<template>
    <h1 class="page-header">
        {{ $t( 'pages.contact.pageHeadline' ) }}
    </h1>

    <div :class="[smallScreenMode ? 'contact-flex' : '']">
        <div class="contact-form">
            <p class="body-text">
                {{ $t( 'pages.contact.paragraphOne' ) }}
            </p>

            <transition name="fade">
                <n-alert
                    type="info"
                    class="mb-3"
                    key="0"
                >
                    {{ $t( 'pages.contact.userInfo' ) }}
                </n-alert>
            </transition>

            <n-form-item
                :label="$t('forms.labels.fullName')"
                :validation-status="fieldErrors.name ? 'error' : null"
                :show-require-mark="true"
                :feedback="fieldErrors.name ? $t(fieldErrors.name) : null"
            >
                <n-input
                    v-model:value="formData.name"
                    type="text"
                    :placeholder="$t('forms.labels.fullName')" />
            </n-form-item>

            <n-form-item
                :label="$t('forms.labels.telNumber')"
                :validation-status="fieldErrors.phone ? 'error' : null"
                :feedback="fieldErrors.phone ? $t(fieldErrors.phone) : null"
            >
                <n-input
                    v-model:value="formData.phone"
                    type="text"
                    :placeholder="$t('forms.labels.telNumber')" />
            </n-form-item>

            <n-form-item
                :label="$t('forms.labels.email')"
                :validation-status="fieldErrors.email ? 'error' : null"
                :show-require-mark="true"
                :feedback="fieldErrors.email ? $t(fieldErrors.email) : null"
            >
                <n-input
                    v-model:value="formData.email"
                    type="text"
                    :placeholder="$t('forms.labels.email')" />
            </n-form-item>

            <n-form-item
                :label="$t( 'forms.labels.message' )"
                :validation-status="fieldErrors.message ? 'error' : null"
                :show-require-mark="true"
                :feedback="fieldErrors.message ? $t(fieldErrors.message) : null"
            >
                <n-input
                    v-model:value="formData.message"
                    type="textarea"
                    :rows="3"
                    :placeholder="$t( 'forms.labels.message' )" />
            </n-form-item>

            <n-form-item
                :class="'switch-right-labeled'"
                :label="$t( 'forms.labels.dsgvo' )"
                :label-placement="'right'"
                :validation-status="fieldErrors.dsgvo ? 'error' : null"
                :show-require-mark="true"
                :feedback="fieldErrors.dsgvo ? $t(fieldErrors.dsgvo) : null"
            >
                <n-switch
                    v-model:value="formData.dsgvo"
                />
            </n-form-item>

            <n-form-item
                class="schutz"
                :label="null"
            >
                <n-input
                    v-model:value="formData.content"
                    type="text"
                    :placeholder="'content'/* Spam-Bot Spam-Schutz */"
                />
            </n-form-item>

            <transition name="fade">
                <n-alert
                    v-if="formMessage !== null"
                    :type="responseType"
                    class="mb-4"
                >
                    {{ formMessage }}
                </n-alert>
            </transition>

            <n-button
                class="mb-3"
                @click.stop.prevent="handleFormSubmit"
                :disabled="disableSubmit"
                :loading="formLoading"
                type="primary"
                ghost
            >
                {{ $t( 'forms.labels.submit' ) }}
            </n-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { useNotification } from 'naive-ui'
import _ from 'lodash'

const initialFormData = {
    name: '',
    phone: '',
    email: '',
    message: '',
    dsgvo: '',
    // Spamschutz https://42he.com/de/blog/2014-07-spamschutz-fuer-formulare-ohne-captcha
    content: ''
}

export default {
    data() {
        return {
            errors: {},
            validFields: [],
            formData: _.cloneDeep( initialFormData ),
            formMessage: null,
            responseType: null,
            formLoading: false,
            notification: useNotification()
        }
    },
    computed: {
        ...mapGetters( [ 'getSmallScreenMode' ] ),
        disableSubmit() {
            return !this.formData.dsgvo || this.formLoading
        },
        fieldErrors() {
            return this.errors
        },
        smallScreenMode() {
            return this.smallScreenMode
        }
    },
    methods: {
        ...mapActions( { sendEmail: 'sendEmail' } ),
        handleApiResponse( response ) {
            let title, message
            const duration = 5000

            if ( response.status === 200 && _.isObject( response.data ) ) {
                if ( response.data.success === true ) {
                    this.formMessage = this.$t( 'api.response.mailSendSucceeded' )
                    this.responseType = 'success'
                    title = this.$t( 'api.titles.success' )
                    message = this.$t( response.data.reason, [ this.formData.email ] )
                    this.formData = _.cloneDeep( initialFormData ) // is defined global!
                }
                else {
                    this.formMessage = this.$t( 'api.response.generalWarning' )
                    this.responseType = 'warning'
                    title = this.$t( 'api.titles.warning' )
                    message = this.$t( response.data.reason )

                    if ( response.data.errors )
                        this.errors = response.data.errors

                    if ( response.data.valid && _.isArray( response.data.valid ) )
                        this.validFields = response.data.valid
                }
            }
            else {
                this.formMessage = this.$t( 'api.error.connection' )
                this.responseType = 'error'
                title = this.$t( 'api.titles.error' )
                message = this.$t( 'api.error.connection' )
            }

            this.noty( this.responseType, title, message, duration )
        },
        async handleFormSubmit() {
            let result
            const sendData = _.merge( this.formData, {
                csrf_hash: window.GLOBAL_FORM_TOKEN,
                locale: this.$i18n.locale
            } )

            this.formMessage = null
            this.responseType = null
            this.errors = {}
            this.validFields = []
            this.formLoading = true

            result = await this.sendEmail( sendData )

            this.formLoading = false
            this.handleApiResponse( result )
        },
        /**
         * Send a notification message to the user on top of the page
         * @see https://www.naiveui.com/en-US/light/components/notification
         * You can find the notification provider <n-notification-provider /> in App.vue
         *
         * @param {string} type                 'error', 'info', 'success', 'warning'
         * @param {string|function} context     Title String or render-function
         * @param {string|function} content     Content String or render-function
         * @param {number} duration             Milliseconds of showing duration time
         */
        noty( type, context, content, duration ) {
            this.notification[ type ]( {
                content: context,
                meta: content,
                duration: duration,
                keepAliveOnHover: true
            } )
        }
    },
    mounted() {
        this.apiErrors = null;
    }
}
</script>

<style scoped>
.contact-flex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
}

.schutz {
    height: 0.5px !important;
    overflow: hidden !important;
}
</style>